<template>
  <div class="custom-stepper">
    <v-stepper v-model="step">
      <!-- Header -->
      <v-stepper-header v-if="items.length">
        <v-stepper-step
          v-for="(item, index) in items"
          :key="`header-${index}`"
          :complete="step > index + 1"
          :step="index + 1"
        >
          {{ item.label }}
        </v-stepper-step>
        <!-- <v-divider v-if="items.length > index + 1"></v-divider> -->
      </v-stepper-header>
      <!-- Content -->
      <v-stepper-items>
        <v-stepper-content
          v-for="(item, index) in items"
          :key="`content-${index}`"
          :step="index + 1"
        >
          <component :is="item.component" :items-data="itemsData" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'CustomStepper',
  props: {
    // Differents "section" inside stepper
    // https://vuetifyjs.com/en/components/steppers/
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      step: 1, // Current step in "stepper"
      itemsData: {} // Global data collected from every "stepper childs"
    }
  },
  mounted() {
    // We listen the child responses
    this.$on('onResetItemsData', this.handleResetItemsData)
    this.$on('onChangeItemsData', this.handleChangeItemsData)
    this.$on('onChangeStep', this.handleChangeStep)
  },
  methods: {
    /**
     * Change the current "step" in "custom stepper"
     *
     * @param {number} step - child step
     */
    handleChangeStep(step) {
      // Se comprueba si es el numero de indice o el identificaro del stepper
      this.step = Number.isNaN(Number.parseInt(step, 10))
        ? this.getIndexItemsFromId(step)
        : step
    },
    /**
     * Parse the "itemsData" to use in "stepper childs"
     *
     * @param {number} step - child step
     * @param {number | string | object | array} response - child step response
     */
    handleChangeItemsData(step, response) {
      const isNan = Number.isNaN(Number.parseInt(step, 10))
      // Set new response
      this.itemsData[step] = response
      this.itemsData = cloneDeep(this.itemsData)
      this.$emit('onCurrentData', this.itemsData)

      if (!isNan) {
        const nextStep = step + 1
        // Emit "onFinish" event or we pass next step
        if (nextStep > this.items.length) {
          this.$emit('onFinish', this.itemsData)
        } else if (nextStep < this.items.length) {
          this.step = nextStep
        } else {
          this.step = this.items.length
        }
      }
    },
    /**
     * Reset the "itemsData" to use in "stepper childs"
     *
     */
    handleResetItemsData() {
      this.itemsData = {}
      this.step = 1
      this.$emit('onCurrentData', this.itemsData)
    },

    /**
     * Devuelve el indice del array de items steppers
     *
     * @param {string} id - Identificaicón de stepper
     */
    getIndexItemsFromId(id) {
      return this.items.findIndex(item => item.id === id) + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.v-stepper__header {
  overflow: hidden;
}
</style>
