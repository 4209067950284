<template>
  <div class="companyActivations-form-stepper-1">
    <v-form novalidate @submit.prevent="onSubmit">
      <!-- companyKeysId -->
      <v-col>
        <v-text-field
          v-model="$v.formFields.companyKeysId.$model"
          v-bind="fieldCompanyKeysVOptions"
          :error-messages="checkFieldErrors('companyKeysId')"
          @input="touchField('companyKeysId')"
          @blur="touchField('companyKeysId')"
        />
      </v-col>
      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="right"
          accept-button-text="Siguiente"
          :cancel-button-hide="true"
          :accept-button-loading="formProcessing"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
// services
import { getCompanyKeysById } from '@/services/companyKeys'

export default {
  name: 'FormStepperCompanyKey',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // Form
    return {
      formFields: {
        companyKeysId: null
      },
      formFieldsValidations: {
        companyKeysId: {
          required: 'Campo obligatorio',
          minLength: 'Debe contener al menos 5 caracteres',
          keyIsValid:
            'El código de cliente no existe. Contacte con soporte para solucionarlo'
        }
      },
      fieldCompanyKeysVOptions: {
        outlined: true,
        dense: true,
        label: 'Codigo Cliente',
        type: 'text'
      }
    }
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        this.formFields = {
          companyKeysId: null
        }
        this.$v.$reset()
      }
    }
  },
  methods: {
    async afterSubmit() {
      try {
        // Obtenemos la información de la company
        const companyKeysData = await getCompanyKeysById(
          this.formFields.companyKeysId
        )

        // Se guardan los datos para utilziarlos al final del proceso
        this.$parent.$parent.$parent.$emit(
          'onChangeItemsData',
          'formCompanyKey',
          {
            companyKeysData,
            ...this.formFields
          }
        )
        // Pasamos al siguiente step
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formTypeActivation')
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      companyKeysId: {
        required,
        minLength: minLength(5),
        // Comprueba si existe el companyKey y si se puede utilizar
        keyIsValid: async value => {
          if (!value || value.length < 5) return true
          const companyKeysData = await getCompanyKeysById(value)
          if (!companyKeysData) return false
          return true
        }
      }
    }
  }
}
</script>
