<template>
  <div class="companykeys-form-stepper-2">
    <v-form novalidate>
      <!-- supportText -->
      <v-row>
        <v-col cols="12">
          <div class="caption grey--text">
            <b>¡Importante!</b> Por favor, facilitanos tus datos de contacto
            como comercial / vendedor para poder contactar contigo en caso de
            necesitarlo desde activación o soporte.
          </div>
        </v-col>
        <v-col cols="12">
          <!-- commercial Name -->
          <v-text-field
            v-model="$v.formFields.commercialName.$model"
            v-bind="formFieldsVOptions.commercialName"
            :error-messages="checkFieldErrors('commercialName')"
            :hide-details="!checkFieldErrors('commercialName').length"
            @input="touchField('commercialName')"
            @blur="touchField('commercialName')"
          />
        </v-col>
        <v-col cols="12">
          <!-- commercial Phone -->
          <v-text-field
            v-model="$v.formFields.commercialEmail.$model"
            v-bind="formFieldsVOptions.commercialEmail"
            :error-messages="checkFieldErrors('commercialEmail')"
            :hide-details="!checkFieldErrors('commercialEmail').length"
            @input="touchField('commercialEmail')"
            @blur="touchField('commercialEmail')"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model.number="$v.formFields.supportText.$model"
            type="text"
            label="Observación"
            outlined
            dense
            color="grey"
            :hide-details="!checkFieldErrors('supportText').length"
            :error-messages="checkFieldErrors('supportText')"
            @input="touchField('supportText')"
            @blur="touchField('supportText')"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-type="button"
          accept-button-text="Finalizar"
          cancel-button-text="Anterior"
          :accept-button-loading="formProcessing"
          @onClickAcceptButton="handleClickAcceptButton"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FormStepperObservations',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      formFields: {
        supportText: null,
        commercialName: null,
        commercialEmail: null
      },
      formFieldsValidations: {
        supportText: {},
        commercialName: {
          required: 'Campo obligatorio'
        },
        commercialEmail: {
          required: 'Campo obligatorio',
          email: 'Email válido'
        }
      },
      formFieldsVOptions: {
        commercialName: {
          outlined: true,
          dense: true,
          label: 'Nombre Comercial / Vendedor*',
          type: 'text'
        },
        commercialEmail: {
          outlined: true,
          dense: true,
          label: 'Email Comercial / Vendedor*',
          type: 'text'
        }
      }
    }
  },
  computed: {
    ...mapGetters('companyActivations', ['commercialData'])
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        // Reset fields and validation
        this.formFields = {
          supportText: null
        }
        this.$v.$reset()
      }
    }
  },
  mounted() {
    this.setInitalValues()
  },
  methods: {
    ...mapMutations('companyActivations', ['setCommercial']),
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setInitalValues() {
      this.formFields = {
        ...this.formFields,
        commercialName: this.commercialData.name,
        commercialEmail: this.commercialData.email
      }
    },
    /**
     * Handle click on "accept" button
     */
    handleClickAcceptButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          await this.onSubmit()
        },
        actionButtonText: 'Finalizar',
        dismissibleTimeOut: -1,
        text: '¿Seguro que desea finalizar el proceso de activación?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Se mueve hacía el stepper anterior
     */
    handleClickCancelButton() {
      // Si venimos desde soporte directamente se redirige al primer stepper
      if (this.itemsData.formTypeActivation.type === 'support') {
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formCompanyKey')
      } else {
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formPlace')
      }
    },
    async afterSubmit() {
      try {
        // Se guardan los datos para utilizarlos al final del proceso
        this.$parent.$parent.$parent.$emit(
          'onChangeItemsData',
          'formObservations',
          {
            ...this.formFields
          }
        )
        // Seteamos los datos del comercial en el estado permanente para tenerlo en todas las solcitudes
        const commercialData = {
          name: this.itemsData.formObservations.commercialName,
          email: this.itemsData.formObservations.commercialEmail
        }
        this.setCommercial(commercialData)
        // Terminamos el step
        this.$parent.$parent.$parent.$emit('onFinish', this.itemsData)
        this.$parent.$parent.$parent.$emit('onResetItemsData')
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: 'Error al comprobar el codigo de cliente',
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations() {
    const validate = {
      formFields: {
        supportText: {},
        commercialName: {},
        commercialEmail: {}
      }
    }
    validate.formFields.commercialName = { required }
    validate.formFields.commercialEmail = { required, email }
    return validate
  }
}
</script>
