<template>
  <div class="companykeys-form-stepper-3">
    <!-- Selected type import place -->
    <v-col>
      <v-select
        v-model="importPlaceType"
        :items="importPlaceTypes"
        item-value="id"
        item-text="name"
        label="¿Como vas a incluir la carta?"
        :hide-details="true"
        outlined
      ></v-select>
    </v-col>

    <div class="text-center grey--text caption">
      <span>{{ textInfoByImportPlaceType }}</span>
    </div>

    <v-form novalidate @submit.prevent="onSubmit">
      <!-- URL o QR -->

      <v-col v-if="importPlaceType === 'url'">
        <div v-if="qrIsActive" class="qr-video">
          <qrcode-stream @decode="qrOnDecode" @init="qrOnInit" />
        </div>
        <div>
          <v-text-field
            ref="field"
            v-model="$v.formFields.placeUrl.$model"
            label="Url del establecimiento"
            type="text"
            :error-messages="checkFieldErrors('placeUrl')"
            @input="touchField('placeUrl')"
            @blur="touchField('placeUrl')"
          />
          <div class="text-center">
            <v-btn class="ma-1" outlined fab color="teal" @click="qrStart">
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            <p class="caption teal--text">Escanear QR</p>
          </div>
        </div>
      </v-col>

      <!-- Upload Images -->
      <v-col v-if="importPlaceType === 'img'">
        <CameraUploader
          ref="cameraUploader"
          enabled-resizer
          :images="formFields.images"
          :max-size-image="maxSizeImage"
          :path-to-save="getPathToSaveImg"
          @onCurrentImages="handleImage"
        />
      </v-col>
      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente"
          cancel-button-text="Anterior"
          :accept-button-loading="formProcessing"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
import CameraUploader from '@/components/ui/CameraUploader'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'

import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'FormStepperPlace',
  components: { FormButtons, CameraUploader, QrcodeStream },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // Form
    return {
      // forms y validations
      formFields: {
        placeUrl: null,
        images: null
      },
      formFieldsValidations: {
        placeUrl: {
          required: 'Campo obligatorio',
          url: 'No es un formato de url valido'
        },
        images: {
          requiredArray: 'Es necesario subir al menos una imagen'
        }
      },
      // Import Place Type
      importPlaceType: 'img',
      importPlaceTypes: [
        {
          id: 'url',
          name: 'Mediante enlace o qr'
        },
        {
          id: 'img',
          name: 'Mediante imagenes'
        }
      ],
      // QR
      qrIsActive: false,
      // Uploads images
      maxSizeImage: {
        width: 1920,
        height: 1080
      }
    }
  },
  computed: {
    // Definimos el directorio de las imagenes
    getPathToSaveImg() {
      const path =
        this.itemsData && this.itemsData.formCompanyKey
          ? this.itemsData.formCompanyKey.companyKeysId
          : 'error'
      return `activations/${path}`
    },
    textInfoByImportPlaceType() {
      return this.importPlaceType === 'url'
        ? 'Introduce el enlace a la carta actual o pulsa sobre el QR para escanear el código directamente.'
        : 'Pulsa en subir fotos y selecciona tantas imágenes como sea necesario. Por favor comprueba que las fotos se ven completas y con la suficiente calidad.'
    }
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        // Reset fields and validation
        this.formFields = {
          placeUrl: null,
          images: null
        }
        this.$v.$reset()
        // Reset images in CameraUploader
        this.$refs.cameraUploader.onResetImages()
      }
    }
  },
  methods: {
    async afterSubmit() {
      try {
        // Se guardan los datos para utilziarlos al final del proceso
        this.$parent.$parent.$parent.$emit('onChangeItemsData', 'formPlace', {
          ...this.formFields
        })
        // Pasamos al siguiente step
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formObservations')
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    },
    // Se mueve hacía el stepper anterior
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 'formStickers')
    },
    // Evento que setea el array de imagenes
    handleImage(value) {
      this.formFields.images = value
    },
    // Invierte el estado (true/false) del qr scaner
    qrStart() {
      this.qrIsActive = !this.qrIsActive
    },
    // Evento cuando se detecta codigo qr
    async qrOnDecode(value) {
      this.formFields.placeUrl = value
      this.qrIsActive = false
    },
    // Control de errores en el qr scaner
    async qrOnInit(promise) {
      try {
        await promise
      } catch (error) {
        this.error = 'No se pudo iniciar la camara'
      }
    }
  },
  // Validations with Vue-validate
  validations() {
    const rules = { formFields: {} }

    if (this.importPlaceType === 'url') {
      rules.formFields = {
        placeUrl: {
          required,
          url
        }
      }
    } else if (this.importPlaceType === 'img') {
      rules.formFields = {
        images: {
          // Comprueba si hay al menos una imagen subida
          requiredArray: value => {
            if (!value || !Array.isArray(value) || value.length <= 0) {
              return false
            }
            return true
          }
        }
      }
    }

    return rules
  }
}
</script>

<style scoped lang="scss">
.url-qr {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 30px;
}
.qr-video {
  max-width: 500px;
  margin: 20px auto;
  width: 100%;
}
</style>
