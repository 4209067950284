<template>
  <div class="file-uploader">
    <!-- Input file -->
    <input
      ref="input"
      class="file-uploader__input"
      type="file"
      :capture="capture"
      :accept="accept"
      :multiple="multiple"
      @change="handleChangeFile"
    />
    <!-- Canvas to resize -->
    <canvas ref="canvas" class="file-uploader__canvas"></canvas>
  </div>
</template>

<script>
// Utils
import { dataURItoBlob } from '@/utils'
// EXIF
const EXIF = require('exif-js/exif')

export default {
  name: 'FileUploader',
  props: {
    // Support types --> https://www.w3schools.com/tags/att_input_accept.asp
    accept: {
      type: String,
      default: 'image/x-png,image/png,image/jpeg,image/jpg'
    },
    capture: {
      type: String,
      default: 'camera'
    },
    maxSizeFiles: {
      type: Number,
      default: 2 // MB
    },
    multiple: {
      type: Boolean,
      default: false
    },
    enabledResizer: {
      type: Boolean,
      default: false
    },
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    }
  },
  data() {
    return {
      processingFiles: false,
      uploadFiles: []
    }
  },
  methods: {
    /**
     * make click on input file
     */
    handleClick() {
      this.$refs.input.click()
    },
    /**
     * Handle event "changeFile"
     *
     * @param {object} $event - object event
     */
    async handleChangeFile($event) {
      this.uploadFiles = Array.from($event.target.files)

      if (!Array.isArray(this.uploadFiles) || this.uploadFiles.length === 0)
        return

      this.processingFiles = true
      this.$emit('onProcessingFiles', this.processingFiles)

      try {
        // Check types files
        this.checkFileTypes(this.uploadFiles, this.accept)

        // Resize images
        if (this.enabledResizer) {
          this.uploadFiles = await this.resizeAllImages(
            this.uploadFiles,
            this.maxSizeImage
          )
        }
        // Check files size
        this.checkMaxSizeFiles(this.uploadFiles, this.maxSizeFiles)

        this.$emit('onUploadFiles', this.uploadFiles)
      } catch (error) {
        this.handleError(error)
      } finally {
        this.processingFiles = false
        this.$emit('onProcessingFiles', this.processingFiles)
      }
    },
    /**
     * Handle error event
     *
     * @param {object} error - send error
     */
    handleError(error) {
      this.$emit('onError', error)
    },
    /**
     * Check file types
     *
     * @param {array} files - uploading files
     * @param {string} accept - type accepted files
     */
    checkFileTypes(files, accept) {
      files.forEach(file => {
        if (accept.indexOf(file.type) === -1) {
          throw new Error('Tipo de fichero no soportado')
        }
      })
    },
    /**
     * Check max size files
     *
     * @param {array} files - uploading files
     * @param {string} maxSize - type accepted files
     */
    checkMaxSizeFiles(files, maxSize) {
      let totalSizeFiles = 0
      files.forEach(file => {
        const fileSizeMB = file.size / 1024 / 1024

        totalSizeFiles += totalSizeFiles + fileSizeMB
        if (totalSizeFiles >= maxSize) {
          throw new Error(
            `Se ha excedido el tamaño máximo ${maxSize} KB de subida de ficheros`
          )
        }
      })
    },
    /**
     * Get orientation about the image
     * ( EXIF only get information about JPG or TIFF images)
     *
     * @param {Image} image - Image or HTMLImageElement
     * @return {number} - orientation
     */
    async getImageOrientation(image) {
      return new Promise(resolve => {
        EXIF.getData(image, function readImageData() {
          resolve(EXIF.getTag(this, 'Orientation'))
        })
      })
    },
    /**
     * Resize images before to upload
     *
     * @param {array} files - uploading files
     * @param {object} sizeImage - new size to resizer
     */
    async resizeAllImages(files, sizeImage) {
      const images = await Promise.all(
        files.map(async file => {
          const orientation = await this.getImageOrientation(file)
          const image = await this.resizeImage(file, sizeImage, orientation)

          image.name = file.name
          return image
        })
      )

      return images
    },
    /**
     * Resize one image with canvas
     *
     * @param {object} file - uploading file
     * @param {object} sizeImage - new size to resizer
     */
    async resizeImage(file, sizeImage) {
      if (!file.type.match(/image.*/)) {
        throw new Error('Tipo de fichero no soportado para redimensionar')
      } else {
        const maxSize = sizeImage
        const reader = new FileReader()
        const image = new Image()
        const { canvas } = this.$refs
        const ctx = canvas.getContext('2d')
        const resize = () => {
          let { width } = image
          let { height } = image

          // Check the new size
          if (width > height) {
            if (width > maxSize.width) {
              height *= maxSize.width / width
              width = maxSize.width
            }
          } else if (height > maxSize.height) {
            width *= maxSize.height / height
            height = maxSize.height
          }

          // Orientating the canvas
          canvas.width = width
          canvas.height = height
          ctx.transform(1, 0, 0, 1, 0, 0)
          // Draw image inside canvas
          ctx.drawImage(image, 0, 0, width, height)

          // Base64 to type BLOB (file)
          const dataUrl = canvas.toDataURL('image/jpeg')
          const fileBlob = dataURItoBlob(dataUrl)

          fileBlob.base64 = dataUrl
          return fileBlob
        }

        return new Promise(resolve => {
          reader.onload = readerEvent => {
            image.onload = () => {
              resolve(resize())
            }
            image.src = readerEvent.target.result
          }
          reader.readAsDataURL(file)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-uploader__canvas {
  display: none;
}
</style>
