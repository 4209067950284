// Constans
import { ACTIVATION_TYPES, ACTIVATION_STATUS } from '@/constants'
// Models
import PanelCompanyActivations from '@/firebase/models/panelCompanyActivations'
// Services
import { getCompanyKeysById } from '../services/companyKeys'
// Utils
import { isNil } from 'lodash'

const panelCompanyActivationsDb = new PanelCompanyActivations()

/**
 * Get panelCompanyActivations by UID
 *
 * @param {string} id - UID panelCompanyActivations in database
 * @return {object} - panel company activations data (promise)
 */
export async function getPanelCompanyActivationById(id) {
  const panelCompanyActivations = await panelCompanyActivationsDb.read(id)
  return panelCompanyActivations
}

/**
 * Get all panel company activations
 *
 * @param {object} pagination - pagination options
 * @return {array} - panel company activations data (array/promise)
 */
export async function getEveryPanelCompanyActivations(pagination) {
  const panelCompanyActivations = await panelCompanyActivationsDb.readAll(
    pagination
  )
  return panelCompanyActivations
}

/**
 * Create new panelCompanyActivations in database
 *
 * @param {object} data - panelCompanyActivations data
 * @return {object} - panel company activations data (promise)
 */
export async function createPanelCompanyActivations(data, id = null) {
  // Creating user in DB
  const panelCompanyActivations = await panelCompanyActivationsDb.create(
    data,
    id
  )

  return panelCompanyActivations
}

/**
 * Update panelCompanyActivations in database
 *
 * @param {object} data - panelCompanyActivations data
 * @return {object} - panel company activations data (promise)
 */
export async function updatePanelCompanyActivations(data) {
  const panelCompanyActivations = await panelCompanyActivationsDb.update(data)
  return panelCompanyActivations
}

/**
 * Remove the panelCompanyActivations by id
 *
 * @param {string} id - UID companyActivations in database
 */
export const delPanelCompanyActivationsById = async id => {
  if (id) {
    await panelCompanyActivationsDb.delete(id)
  }
  return false
}

/**
 * Get the next companyActivations to create
 */
export async function getNextCompanyActivation() {
  const companyActivations = await getEveryPanelCompanyActivations({
    constraints: [
      ['type', '==', ACTIVATION_TYPES.assistant.value],
      ['status', '==', ACTIVATION_STATUS.pending.value]
    ],
    order: {
      field: 'createTimestamp',
      direction: 'asc'
    },
    forceOrder: true,
    limit: 1
  })
  const activation = companyActivations.shift()

  if (!isNil(activation)) {
    // Obtenemos datos del estado del código de la compañía
    const companyKey = await getCompanyKeysById(activation.companyKeysId)
    if (!companyKey.enabled) {
      // Actualizamos el estado de la activación
      await updatePanelCompanyActivations({
        id: activation.id,
        status: ACTIVATION_STATUS.done.value,
        type: ACTIVATION_TYPES.self.value
      })
      // recursividad
      return await getNextCompanyActivation()
    }
    // devolvemos la activación
    return activation
  }
  return false
}

/**
 * Comprobamos si el email se ha usado en otra
 * petición de activación
 *
 * @param {string} email - user email
 */
export const isEmailAvailable = async email => {
  if (email) {
    const types = [
      ACTIVATION_TYPES.self.value,
      ACTIVATION_TYPES.assistant.value
    ]

    const activations = await Promise.all(
      types.map(async type => {
        const activation = await panelCompanyActivationsDb.readAll({
          constraints: [
            ['email', '==', email],
            ['type', '==', type]
          ],
          limit: 1,
          order: null
        })
        return activation
      })
    )

    return !activations.reduce((sumActivations, activation) => {
      sumActivations += activation.length
      return sumActivations
    }, 0)
  }

  return false
}
