<template>
  <div class="companyActivations-form-stepper-3">
    <v-form novalidate @submit.prevent="onSubmit">
      <!-- radio selected type -->
      <v-container fluid>
        <v-radio-group
          v-model="$v.formFields.type.$model"
          :error-messages="checkFieldErrors('type')"
          @input="touchField('type')"
          @blur="touchField('type')"
        >
          <v-radio
            v-for="(option, index) in getActivationsOptionsTypes"
            :key="index"
            :label="option.label"
            :value="option.value"
          />
        </v-radio-group>
        <div class="caption grey--text mt-4">
          La opción de "Soporte" es solo para solicitar ayuda al equipo de
          soporte en caso de alguna incidencia. Recuerda que también tienes a tu
          disposición el tlf 673805947 o el email
          <a href="mailto:ayuda@cartadigitalbyschweppes.com">
            ayuda@cartadigitalbyschweppes.com
          </a>
        </div>
      </v-container>

      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente"
          cancel-button-text="Anterior"
          :accept-button-loading="formProcessing"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Constans
import { ACTIVATION_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Utils
import { get } from 'lodash'

export default {
  name: 'FormStepperTypeActivation',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    // Form
    return {
      column: null,
      formFields: {
        type: ACTIVATION_TYPES.self.value
      },
      formFieldsValidations: {
        type: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  computed: {
    /**
     * Get the activations options
     *
     * @return {array} - activations options types
     */
    getActivationsOptionsTypes() {
      const companyEnabled = get(
        this.itemsData,
        'formCompanyKey.companyKeysData.enabled',
        true
      )
      return companyEnabled
        ? [
            {
              label: ACTIVATION_TYPES.self.label,
              value: ACTIVATION_TYPES.self.value
            },
            {
              label: ACTIVATION_TYPES.assistant.label,
              value: ACTIVATION_TYPES.assistant.value
            },
            {
              label: ACTIVATION_TYPES.support.label,
              value: ACTIVATION_TYPES.support.value
            }
          ]
        : [
            {
              label: ACTIVATION_TYPES.support.label,
              value: ACTIVATION_TYPES.support.value
            }
          ]
    }
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        // Reset fields and validation
        this.formFields = {
          type: null
        }
        this.$v.$reset()
      }
    }
  },
  methods: {
    async afterSubmit() {
      try {
        // Se guardan los datos para utilziarlos al final del proceso
        this.$parent.$parent.$parent.$emit(
          'onChangeItemsData',
          'formTypeActivation',
          {
            ...this.formFields
          }
        )

        // Se define hacía que steper cambiamos segun el type seleccioando
        const stepNumber =
          this.formFields.type === 'support' || this.formFields.type === 'no'
            ? 'formObservations'
            : 'formInformationBasic'

        // Pasamos al siguiente step
        this.$parent.$parent.$parent.$emit('onChangeStep', stepNumber)
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    },
    // Se mueve hacía el stepper anterior
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 'formCompanyKey')
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      type: {
        required
      }
    }
  }
}
</script>
