import CompanyKeys from '@/firebase/models/companyKeys'
import { ACTIVATION_TYPES } from '@/constants'

const CompanyKeysDb = new CompanyKeys()

const moment = require('moment')

/**
 * Get companyKeys by UID
 *
 * @param {string} id - UID companyKeys in database
 */
export async function getCompanyKeysById(id) {
  const companyKeys = await CompanyKeysDb.read(id)
  return companyKeys
}

/**
 * Get all companyKeys
 *
 * @param {object} pagination - pagination options
 * @return {array} - panel user data (array/promise)
 */
export async function getAllCompanyKeys(pagination) {
  const companyKeys = await CompanyKeysDb.readAll(pagination)
  return companyKeys
}

/**
 * Get all companyKeys whit activation pending of any type
 *
 * @param {timestamp} dateFrom
 * @param {timestamp} dateTo
 * @return {String} - area - Nº del area
 */
export async function getAllCompanyKeysWithCompanyActivation(
  dateFrom,
  dateTo,
  area
) {
  // Buscamos los companyKeys por los estados de activaciones que necesitamos
  const companyKeysSelfType = await CompanyKeysDb.readAll({
    constraints: [
      ['updateTimestamp', '>=', dateFrom],
      ['updateTimestamp', '<=', dateTo],
      ['area', '==', area],
      ['activationType', '==', ACTIVATION_TYPES.self.value],
      ['enabled', '==', true]
    ],
    limit: null
  })
  const companyKeysAssistantType = await CompanyKeysDb.readAll({
    constraints: [
      ['updateTimestamp', '>=', dateFrom],
      ['updateTimestamp', '<=', dateTo],
      ['area', '==', area],
      ['activationType', '==', ACTIVATION_TYPES.assistant.value],
      ['enabled', '==', true]
    ],
    limit: null
  })
  const companyKeysRejectedType = await CompanyKeysDb.readAll({
    constraints: [
      ['updateTimestamp', '>=', dateFrom],
      ['updateTimestamp', '<=', dateTo],
      ['area', '==', area],
      ['activationType', '==', ACTIVATION_TYPES.rejected.value],
      ['enabled', '==', true]
    ],
    limit: null
  })
  // Unimos los 3 arrays en uno solo para su devolución
  const companyKeys = [
    ...companyKeysSelfType,
    ...companyKeysAssistantType,
    ...companyKeysRejectedType
  ]
  return companyKeys
}

/**
 * Get all companyKeys activateds and whit activation pending to build the excel
 *
 * @param {timestamp} dateFrom
 * @param {timestamp} dateTo
 * @return {array} - areas - Nº de areas
 */
export async function getAllCompanyKeysActivatedAndWithCompanyActivationToExcel(
  dateFrom,
  dateTo,
  areas
) {
  const companyKeysKPIsDetails = await Promise.all(
    areas.map(async area => {
      /**
       *  Obtenemos todas las companyKeys con activaciones solicitados en los tipos "self", "assistant" y "rejected"
       *  entre las fechas solicitadas
       * */
      const allCompanyKeysWithActivationPendingByArea = await getAllCompanyKeysWithCompanyActivation(
        dateFrom,
        dateTo,
        area
      )
      // Obtenemos los companyKeys ya activados por área y entre las fechas solicitadas
      const allComanyKeysActivated = await getAllCompanyKeys({
        constraints: [
          ['enabled', '==', false],
          ['updateTimestamp', '>=', dateFrom],
          ['updateTimestamp', '<=', dateTo],
          ['area', '==', area]
        ],
        limit: null
      })
      // Unimos los arrays en uno solo
      const result = [
        ...allComanyKeysActivated,
        ...allCompanyKeysWithActivationPendingByArea
      ]

      return result
    })
  )

  // Una vez tenemos todos los array los concatenamos en uno solo
  const allCompanyKeysKPIsDetailsToExcel = companyKeysKPIsDetails[0].concat(
    companyKeysKPIsDetails[1] ? companyKeysKPIsDetails[1] : [],
    companyKeysKPIsDetails[2] ? companyKeysKPIsDetails[2] : [],
    companyKeysKPIsDetails[3] ? companyKeysKPIsDetails[3] : [],
    companyKeysKPIsDetails[4] ? companyKeysKPIsDetails[4] : []
  )

  // Recorremos el array anterior para montar uno con objetos con los campos que necesitamos para el excel
  const companyKeysKPIsDetailsToExcel = await allCompanyKeysKPIsDetailsToExcel.reduce(
    (sumCompanyKeys, companyKey) => {
      companyKey.status = 'Activada'
      companyKey.incidencia = ''
      companyKey.originalRequest = ''
      companyKey.finalRequest = ''
      // Seteamos algunos valores dependiendo del tipo de activación
      if (companyKey.enabled && companyKey.activationType) {
        if (companyKey.activationType === 'self') {
          companyKey.status = 'Auto-Activación'
        }
        if (companyKey.activationType === 'assistant') {
          companyKey.status = 'Asistida'
        }
        if (companyKey.activationType === 'rejected') {
          companyKey.status = 'En Revisión'
          companyKey.incidencia = companyKey.activationRejectionText
            ? companyKey.activationRejectionText
            : ''
        }
      } else {
        if (companyKey.activationStatusHistory) {
          const lengthHistory = companyKey.activationStatusHistory.length - 1
          if (companyKey.activationStatusHistory[0].type === 'self') {
            companyKey.originalRequest = 'Auto-Activación'
          }
          if (companyKey.activationStatusHistory[0].type === 'assistant') {
            companyKey.originalRequest = 'Asistida'
          }
          if (companyKey.activationStatusHistory[0].type === 'rejected') {
            companyKey.originalRequest = 'En Revisión'
          }

          if (
            companyKey.activationStatusHistory[lengthHistory].type === 'self'
          ) {
            companyKey.finalRequest = 'Auto-Activación'
          }
          if (
            companyKey.activationStatusHistory[lengthHistory].type ===
            'assistant'
          ) {
            companyKey.finalRequest = 'Asistida'
          }
          if (
            companyKey.activationStatusHistory[lengthHistory].type ===
            'rejected'
          ) {
            companyKey.finalRequest = 'En Revisión'
          }
        }
      }

      let dateCreateActivation = ''
      if (!companyKey.enabled) {
        dateCreateActivation = moment(companyKey.updateTimestamp).format(
          'DD-MM-YYYY'
        )
      }

      let dateCreateRequest = moment(companyKey.updateTimestamp).format(
        'DD-MM-YYYY'
      )
      if (companyKey.activationCreated) {
        dateCreateRequest = moment(companyKey.activationCreated).format(
          'DD-MM-YYYY'
        )
      }

      // Construimos el objeto con los datos que necesitamos en el excel
      sumCompanyKeys.push({
        area: companyKey.area,
        management: `${companyKey.management} ${companyKey.managementName}`,
        promoter: companyKey.promoter,
        route: companyKey.route,
        code: companyKey.code,
        companyName: companyKey.companyName,
        status: companyKey.status,
        originalRequest: companyKey.originalRequest,
        finalRequest: companyKey.finalRequest,
        incidence: companyKey.incidencia,
        dateCreateRequest,
        dateCreateActivation
      })

      return sumCompanyKeys
    },
    []
  )
  return companyKeysKPIsDetailsToExcel
}

/**
 * Create new companyKeys
 *
 * @param {object} data - companyKeys data
 * @param {string} id - id item
 */
export async function createCompanyKeys(data, id = null) {
  const company = await CompanyKeysDb.create(data, id)
  return company
}

/**
 * Create companyKeys (import) from data array
 *
 * @param {array} data - companyKeys data
 * @return {object} - panel user data (promise)
 */
export async function createCompanyKeysFromArray(data) {
  if (Array.isArray(data)) {
    await Promise.all(
      data.map(async item => {
        const { id, ...params } = item
        await CompanyKeysDb.create(params, id)
      })
    )
  }
  return false
}

/**
 * Update companyKeys in database
 *
 * @param {object} data - companyKeys data
 */
export async function updateCompanyKeys(data) {
  const companyKeys = await CompanyKeysDb.update(data)
  return companyKeys
}

/**
 * Remove the companyKeys by id
 *
 * @param {string} id - UID companyKeys in database
 */
export async function delCompanyKeysById(id) {
  if (id) {
    await CompanyKeysDb.delete(id)
  }
  return false
}
