<template>
  <div class="companyActivations-form-stepper-2">
    <v-form novalidate @submit.prevent="onSubmit">
      <v-col>
        <!-- userName -->
        <v-text-field
          v-model="$v.formFields.userName.$model"
          v-bind="formFieldsVOptions.userName"
          :error-messages="checkFieldErrors('userName')"
          @input="touchField('userName')"
          @blur="touchField('userName')"
        />
        <!-- email -->
        <v-text-field
          v-model="$v.formFields.email.$model"
          v-bind="formFieldsVOptions.email"
          :error-messages="checkFieldErrors('email')"
          @input="touchField('email')"
          @blur="touchField('email')"
        />
        <!-- phone -->
        <v-text-field
          v-model="$v.formFields.phone.$model"
          v-bind="formFieldsVOptions.phone"
          :error-messages="checkFieldErrors('phone')"
          @input="touchField('phone')"
          @blur="touchField('phone')"
        />
        <!-- address -->
        <v-text-field
          v-model="$v.formFields.address.$model"
          v-bind="formFieldsVOptions.address"
          :error-messages="checkFieldErrors('address')"
          @input="touchField('address')"
          @blur="touchField('address')"
        />
        <!-- postal_code -->
        <v-text-field
          v-model="$v.formFields.postal_code.$model"
          v-bind="formFieldsVOptions.postal_code"
          :error-messages="checkFieldErrors('postal_code')"
          @input="touchField('postal_code')"
          @blur="touchField('postal_code')"
        />
      </v-col>
      <!-- action buttons -->
      <v-card-actions>
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente"
          cancel-button-text="Anterior"
          :accept-button-loading="formProcessing"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Services
import { isEmailAvailable } from '@/services/panelCompanyActivations'

export default {
  name: 'FormStepperInformationBasic',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    // Form
    return {
      formFields: {
        userName: null,
        email: null,
        phone: null,
        address: null,
        postal_code: null
      },
      formFieldsValidations: {
        userName: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email',
          emailAvailable: 'El email ya ha sido usado para otra solicitud'
        },
        phone: {
          required: 'Campo obligatorio'
        },
        address: {
          required: 'Campo obligatorio'
        },
        postal_code: {
          required: 'Campo obligatorio'
        }
      },
      formFieldsVOptions: {
        userName: {
          outlined: true,
          dense: true,
          label: 'Nombre Cliente',
          type: 'text'
        },
        email: {
          outlined: true,
          dense: true,
          label: 'Email',
          type: 'email'
        },
        phone: {
          outlined: true,
          dense: true,
          label: 'Teléfono',
          type: 'text'
        },
        address: {
          outlined: true,
          dense: true,
          label: 'Dirección',
          type: 'text'
        },
        postal_code: {
          outlined: true,
          dense: true,
          label: 'Código Postal',
          type: 'text'
        }
      }
    }
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        // Reset fields and validation
        this.formFields = {
          userName: null,
          email: null,
          phone: null,
          address: null,
          postal_code: null
        }
        this.$v.$reset()
      }
    }
  },
  methods: {
    async afterSubmit() {
      try {
        // Se guardan los datos para utilziarlos al final del proceso
        this.$parent.$parent.$parent.$emit(
          'onChangeItemsData',
          'formInformationBasic',
          {
            ...this.formFields
          }
        )
        // Pasamos al siguiente step
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formStickers')
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    },
    // Se mueve hacía el stepper anterior
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 'formTypeActivation')
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      userName: {
        required
      },
      email: {
        required,
        email,
        emailAvailable: async value => {
          let available = false

          if (value && value.length > 5) {
            available = await isEmailAvailable(value)
          }
          return available
        }
      },
      phone: {
        required
      },
      address: {
        required
      },
      postal_code: {
        required
      }
    }
  }
}
</script>
