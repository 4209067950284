<template>
  <!-- <v-card class="elevation-0"> -->
  <BrandCustomCard subtitle="Activación Clientes">
    <!-- <v-card-text> -->
    <div v-if="getTitleCompany" class="text-center py-3 blue lighten-5">
      <h3>{{ getTitleCompany }}</h3>
    </div>
    <CustomStepper
      :items="stepperItems"
      @onFinish="handleFinish"
      @onCurrentData="handleCurrentData"
    />
    <!-- </v-card-text> -->
  </BrandCustomCard>
  <!-- </v-card> -->
</template>

<script>
// Constans
import { ACTIVATION_STATUS, ACTIVATION_TYPES } from '@/constants'
// Components
import BrandCustomCard from '@/components/ui/BrandCustomCard'
import CustomStepper from '@/components/ui/CustomStepper'
import FormStepperCompanyKey from '@/components/elements/companyActivations/FormStepperCompanyKey'
import FormStepperInformationBasic from '@/components/elements/companyActivations/FormStepperInformationBasic'
import FormStepperTypeActivation from '@/components/elements/companyActivations/FormStepperTypeActivation'
import FormStepperStickers from '@/components/elements/companyActivations/FormStepperStickers'
import FormStepperPlace from '@/components/elements/companyActivations/FormStepperPlace'
import FormStepperObservations from '@/components/elements/companyActivations/FormStepperObservations'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { createPanelCompanyActivations } from '@/services/panelCompanyActivations'
import { updateCompanyKeys } from '@/services/companyKeys'
// Utils
import { get } from 'lodash'

export default {
  name: 'ActivationForm',
  components: {
    BrandCustomCard,
    CustomStepper
  },
  mixins: [uiMixin],
  data() {
    return {
      // Data de stepers
      currentData: null,
      // Diferent sections (items) inside "stepper"
      stepperItems: [
        {
          label: 'Codigo',
          component: FormStepperCompanyKey,
          id: 'formCompanyKey'
        },
        {
          label: 'Tipo Activación',
          component: FormStepperTypeActivation,
          id: 'formTypeActivation'
        },
        {
          label: 'Información',
          component: FormStepperInformationBasic,
          id: 'formInformationBasic'
        },
        {
          label: 'Stickers',
          component: FormStepperStickers,
          id: 'formStickers'
        },
        {
          label: 'Carta',
          component: FormStepperPlace,
          id: 'formPlace'
        },
        {
          label: 'Observacion',
          component: FormStepperObservations,
          id: 'formObservations'
        }
      ]
    }
  },
  computed: {
    // Devuelve el nombre de la compañia
    getTitleCompany() {
      return get(
        this.currentData,
        'formCompanyKey.companyKeysData.companyName',
        null
      )
    }
  },
  methods: {
    /**
     * Handle event when the stepper is finished
     *
     * @param {array} data - every data collected inside stepper
     */
    async handleFinish(data) {
      // Generamos la estructura del modelo DB
      const type = get(data, 'formTypeActivation.type', null)

      const dataFormat = {
        companyKeysId: get(data, 'formCompanyKey.companyKeysId', null),
        area: get(data, 'formCompanyKey.companyKeysData.area', null),
        userName: get(data, 'formInformationBasic.userName', null),
        companyName: get(
          data,
          'formCompanyKey.companyKeysData.companyName',
          null
        ),
        email: get(data, 'formInformationBasic.email', null),
        phone: get(data, 'formInformationBasic.phone', null),
        address: get(data, 'formInformationBasic.address', null),
        postalCode: get(data, 'formInformationBasic.postal_code', null),
        type,
        stickers: get(data, 'formStickers', null),
        images: get(data, 'formPlace.images', null),
        placeUrl: get(data, 'formPlace.placeUrl', null),
        status:
          type === ACTIVATION_TYPES.noActivation.value
            ? null
            : ACTIVATION_STATUS.pending.value,
        supportText: get(data, 'formObservations.supportText', null),
        commercialName: get(data, 'formObservations.commercialName', null),
        commercialEmail: get(data, 'formObservations.commercialEmail', null)
      }

      // Guardamos la activación en DBdeliveryType
      await createPanelCompanyActivations(dataFormat)
      // Guardamos otros datos en "companyKeys"
      await updateCompanyKeys({
        id: dataFormat.companyKeysId,
        activationType: dataFormat.type,
        activationStatus: dataFormat.status
      })

      this.modifyAppAlert({
        text: 'Se ha guardado correctamente',
        visible: true
      })
    },
    /**
     * Actualiza los datos que añaden los steppers
     *
     * @param {object} data - itemsData
     */
    handleCurrentData(data) {
      this.currentData = data
    }
  }
}
</script>

<style lang="scss">
.v-stepper__header {
  display: none;
}
.v-stepper {
  box-shadow: none;
}
.v-stepper__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
