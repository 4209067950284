<template>
  <div class="custom-card">
    <v-card v-bind="getCardVOptions">
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'CustomCard',
  props: {
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    cardVOptions: {
      default() {
        return {
          height: '100%',
          width: '100%',
          elevation: 0
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    /**
     * Get options (computed) to v-card
     */
    getCardVOptions() {
      return this.isMobile
        ? {
            ...this.cardVOptions,
            elevation: 0
          }
        : this.cardVOptions
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.custom-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > .v-card {
    padding: 2rem 1.5rem;
    .v-card__actions {
      padding: 0;
      margin-top: 1rem;
    }
  }
}
@media (max-width: 768px) {
  .custom-card {
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding: 0;
    & > .v-card {
      height: 100%;
      border-radius: 0 !important;
      .v-card__actions {
        background: $theme-background;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 0;
        padding: 1rem;
      }
    }
  }
}
</style>
