<template>
  <div>
    <img :src="image" />
  </div>
</template>

<script>
export default {
  name: 'PreviewImage',
  props: {
    image: {
      default: '',
      type: String
    }
  }
}
</script>
