// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class PanelCompanyActivations extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    address: null,
    area: null,
    companyKeysId: null,
    companyName: null,
    email: null,
    images: null,
    panelUserId: null, // he/she maked the activation
    phone: null,
    postalCode: null,
    placeUrl: null,
    // type: 'self' --> 'pending' & 'done'
    // type: 'assistant' --> 'pending', 'in process', 'done'
    // type: 'support' --> 'pending', 'in process', 'done'
    // type: 'no' --> 'no status'
    status: null,
    supportText: null,
    stickers: {},
    type: 'self', // 'self', 'assistant', 'support'
    userName: null
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.panel}companyActivations`)
  }
}
