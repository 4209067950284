<template>
  <div class="companykeys-form-stepper-2">
    <v-form novalidate @submit.prevent="onSubmit">
      <!-- Pequeñas -->
      <v-col>
        <v-text-field
          v-model.number="$v.formFields.sticker12.$model"
          type="number"
          label="Pegatinas Pequeñas"
          outlined
          dense
          color="grey"
          :hide-details="!checkFieldErrors('sticker12').length"
          :error-messages="checkFieldErrors('sticker12')"
          :messages="['Tamaño 12x12 cm']"
          @input="touchField('sticker12')"
          @blur="touchField('sticker12')"
        ></v-text-field>
      </v-col>
      <!-- Grande -->
      <v-col>
        <v-text-field
          v-model.number="$v.formFields.sticker30.$model"
          type="number"
          label="Pegatinas Grandes"
          outlined
          dense
          color="grey"
          :hide-details="!checkFieldErrors('sticker30').length"
          :error-messages="checkFieldErrors('sticker30')"
          :messages="['Tamaño 30x30 cm']"
          @input="touchField('sticker30')"
          @blur="touchField('sticker30')"
        ></v-text-field>
      </v-col>

      <!-- <v-container fluid>
        <v-radio-group
          v-model="$v.formFields.deliveryType.$model"
          :error-messages="checkFieldErrors('deliveryType')"
          @input="touchField('deliveryType')"
          @blur="touchField('deliveryType')"
        >
          <v-radio label="Enviar a establecimiento" value="send"></v-radio>
          <v-radio label="Recoger en imprenta" value="collected"></v-radio>
        </v-radio-group>
      </v-container> -->

      <!-- action buttons -->
      <v-card-actions class="mt-4">
        <FormButtons
          align-buttons="space-between"
          accept-button-text="Siguiente"
          cancel-button-text="Anterior"
          :accept-button-loading="formProcessing"
          @onClickCancelButton="handleClickCancelButton"
        />
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'FormStepperStickers',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Data from parent "stepper"
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      formFields: {
        sticker12: 10,
        sticker30: 2,
        deliveryType: 'send'
      },
      formFieldsValidations: {
        sticker12: {
          required: 'Debes introducir un numero',
          numeric: 'El dato introducido no es numérico'
        },
        sticker30: {
          required: 'Debes introducir un numero',
          numeric: 'El dato introducido no es numérico'
        }
      }
    }
  },
  watch: {
    itemsData(newVal) {
      if (
        typeof newVal === 'object' &&
        newVal !== null &&
        Object.keys(newVal).length === 0
      ) {
        // Reset fields and validation
        this.formFields = {
          sticker12: 10,
          sticker30: 2,
          deliveryType: 'send'
        }
        this.$v.$reset()
      }
    }
  },
  methods: {
    async afterSubmit() {
      try {
        // Se guardan los datos para utilziarlos al final del proceso
        this.$parent.$parent.$parent.$emit(
          'onChangeItemsData',
          'formStickers',
          {
            ...this.formFields
          }
        )
        // Pasamos al siguiente step
        this.$parent.$parent.$parent.$emit('onChangeStep', 'formPlace')
      } catch (error) {
        this.modifyAppAlert({
          dismissibleTimeOut: -1,
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Se desabilita el loading del boton form
        this.formProcessing = false
      }
    },
    // Se mueve hacía el stepper anterior
    handleClickCancelButton() {
      this.$parent.$parent.$parent.$emit('onChangeStep', 'formInformationBasic')
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      sticker12: {
        required,
        numeric
      },
      sticker30: {
        required,
        numeric
      },
      deliveryType: {
        required
      }
    }
  }
}
</script>
