// Constants
import { PREFIX_COLLECTIONS, ACTIVATION_TYPES } from '@/constants'
import GenericDB from '../generic-db'

export default class CompanyKeys extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    area: null,
    activationStatus: null,
    activationType: ACTIVATION_TYPES.noActivation.value,
    code: null,
    companyName: null,
    enabled: true
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}companyKeys`)
  }

  // Here you can extend Company with custom methods
}
