// Vuex
import { mapMutations } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  data() {
    return {
      formFieldsValidations: {}, // Opciones de las validaciones de los campos
      formDefaultError: 'Comprueba los errores del formulario',
      formDefaultFieldError: 'Campo no completado correctamente',
      formProcessing: false
    }
  },
  methods: {
    ...mapMutations('app', ['setAppAlert']),
    /**
     * Default method to submit form.
     * It is possible that the child view has a owner method
     */
    async onSubmit() {
      try {
        let returnBeforeSubmit = null
        // Launch function before submit formulary (optional)
        if (typeof this.beforeSubmit === 'function') {
          returnBeforeSubmit = await this.beforeSubmit()
        }

        // Launch default submit action form
        this.actionSubmit()

        // Launch function after submit formulary (optional)
        if (typeof this.afterSubmit === 'function') {
          await this.afterSubmit(returnBeforeSubmit)
        }
      } catch (error) {
        this.showErrorSubmit()
      }
    },

    /**
     * Submit action
     * @return {promise}
     */
    actionSubmit() {
      this.$v.formFields.$touch()
      if (!this.$v.formFields.$invalid) {
        this.formProcessing = true
      } else {
        // Esto aquí es redundate (lo que devuelve el error, no el error en si)
        // ya que este mensaje se mostrará en "showErrorSubmit"
        throw new Error(this.formError || this.formDefaultError)
      }
    },

    /**
     * Show error submit action
     */
    showErrorSubmit() {
      this.setAppAlert({
        text: this.formError || this.formDefaultError,
        type: 'error',
        visible: true
      })
    },

    /**
     * Check the possible errors in the current field
     *
     * @param {string} field - name field in model
     * @return {null | false} Pristine or Error
     */
    checkFieldErrors(field) {
      const vField = get(this, `$v.formFields.${field}`, false)
      // Remove $each from field
      const fieldNoEach = field.replace(/\$each\[\d{1,4}\]\./i, '')
      const formFieldsValidations = get(
        this,
        `formFieldsValidations.${fieldNoEach}`,
        false
      )
      const errors = []

      if (vField && vField.$dirty && vField.$error) {
        if (formFieldsValidations) {
          Object.entries(vField)
            .filter(error => !error[1] && formFieldsValidations[error[0]])
            .forEach(error => {
              errors.push(formFieldsValidations[error[0]])
            })
        } else {
          errors.push(this.formDefaultFieldError)
        }
      }
      return errors
    },

    /**
     * Touch field input
     *
     * @param {string} field - name field in model
     */
    touchField(field) {
      const currentField = get(this, `$v.formFields.${field}`, false)
      if (currentField) {
        currentField.$touch()
      }
    }
  }
}
