<template>
  <div class="brand-custom-card">
    <CustomCard :card-v-options="getCardVOptions">
      <!-- title -->
      <v-card-title v-if="title" class="title">
        {{ title }}
      </v-card-title>
      <!-- Brand -->
      <div v-else class="brand">
        <img :src="getBrandImage" />
      </div>
      <!-- Subtitle -->
      <v-card-subtitle v-if="subtitle" class="subtitle">
        {{ subtitle }}
      </v-card-subtitle>
      <!-- Content card -->
      <slot></slot>
    </CustomCard>
  </div>
</template>
<script>
// Utils
import { getPathImage } from '@/utils'
// Components
import CustomCard from '@/components/ui/CustomCard'

export default {
  name: 'BrandCustomCard',
  components: { CustomCard },
  props: {
    title: String,
    subtitle: String
  },
  computed: {
    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand-form.png', true)
    },
    /**
     * Options to CustomCard component
     */
    getCardVOptions() {
      return {
        elevation: 1,
        width: '100%',
        'max-width': '500px'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.brand-custom-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $theme_color;
  & > .custom-card {
    height: 100%;
    padding: 2rem 0;
    .brand {
      display: flex;
      flex-direction: row;
      justify-content: center;
      & > img {
        width: auto;
      }
    }
    .title {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: normal;
    }
    .subtitle {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1rem;
      text-align: center;
    }
  }
}
@media (max-width: 768px) {
  .brand-custom-card {
    & > .custom-card {
      padding: 0;
      overflow-y: none;
      position: inherit;
      & > .v-card {
        height: 100%;
        .v-card__actions {
          margin-top: 1rem;
          background: transparent;
          position: inherit;
          width: inherit;
          padding: inherit;
        }
      }
    }
  }
}
</style>
