<template>
  <div v-if="loading" class="content-loading">
    <v-progress-circular v-bind="loadingVOptions" />
  </div>
</template>

<script>
export default {
  name: 'ContentLoading',
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/progress-circular/#progress-circular
     */
    loadingVOptions: {
      default() {
        return {
          size: '50',
          width: '4',
          indeterminate: true,
          color: 'secondary'
        }
      },
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.content-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
