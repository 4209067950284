import firebase from 'firebase/app'
import 'firebase/storage'

/**
 * Upload file to server
 *
 * @param {object} file - file BLOB to upload
 * @return {object} - reference storage
 */
export const uploadFile = async (file, path) => {
  if (file && path) {
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(path)

    // Upload file
    await fileRef.put(file, { type: file.type })

    return fileRef
  }
  return false
}

/**
 * Delete file from reference
 *
 * @param {object} ref - reference in firebase
 */
export const deleteFile = async ref => {
  if (ref) {
    return ref.delete()
  }
  return false
}

/**
 * Get download URL from reference
 *
 * @param {object} url - file BLOB to upload
 * @return {object} - reference to file
 */
export const getDownloadURLFromRef = async ref => {
  if (ref) {
    const url = await ref.getDownloadURL()
    return url
  }
  return false
}

/**
 * Get reference from URL
 *
 * @param {object} url - file BLOB to upload
 * @return {object} - reference to file
 */
export const refFromUrl = async url => {
  if (url) {
    const ref = await firebase.storage().refFromURL(url)
    return ref
  }
  return false
}
