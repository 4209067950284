<template>
  <div class="camera-uploader">
    <!-- Action button -->
    <div class="camera-uploader__button-upload" @click="handleAddImage">
      <template v-if="!uploaderIsBusy">
        <v-btn x-large elevation="0" icon>
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-chip small color="secondary">{{ labelButtonUpload }}</v-chip>
      </template>
      <template v-else>
        <ContentLoading :loading="uploaderIsBusy" />
      </template>
    </div>
    <!-- Images listed -->
    <div v-if="currentImages.length" class="camera-uploader__list-items">
      <div class="items">
        <div v-for="(image, index) in currentImages" :key="index" class="item">
          <div class="image" :style="`background-image:url(${image})`">
            <v-chip
              class="delete-image"
              small
              color="error"
              @click="handleRemoveImage(index)"
            >
              X
            </v-chip>
            <v-chip small color="primary" @click="handlePreviewImage(index)">
              {{ labelButtonPreview }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <!-- Component to upload files -->
    <FileUploader
      ref="fileUploader"
      :capture="capture"
      :enabled-resizer="enabledResizer"
      :max-size-image="maxSizeImage"
      class="camera-uploader__file-uploader"
      @onError="handleError"
      @onUploadFiles="handleUploadFiles"
      @onProcessingFiles="handleProcessingFiles"
    />
  </div>
</template>

<script>
// Storage
import {
  uploadFile,
  deleteFile,
  refFromUrl,
  getDownloadURLFromRef
} from '@/services/storage'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import ContentLoading from './ContentLoading'
import FileUploader from './FileUploader'
import PreviewImage from '@/components/ui/PreviewImage'
// utils
const uniqid = require('uniqid')

export default {
  name: 'CameraUploader',
  components: { ContentLoading, FileUploader },
  mixins: [uiMixin],
  props: {
    capture: {
      type: [String, Boolean],
      default: 'camera'
    },
    /**
     * Is enabled the resizer images?
     */
    enabledResizer: {
      type: Boolean,
      default: true
    },
    /**
     * Images to show in the list
     */
    images: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * Label to delete button
     */
    labelButtonPreview: {
      type: String,
      default: 'Vista previa'
    },
    /**
     * Label to upload button
     */
    labelButtonUpload: {
      type: String,
      default: 'Subir fotos'
    },
    /**
     * Maximum size allowed to upload a image
     */
    maxSizeImage: {
      type: Object,
      default() {
        return {
          width: 1280,
          height: 720
        }
      }
    },
    /**
     * Path (firebase storage) where the images will be save
     */
    pathToSave: {
      type: String,
      default: 'tests'
    }
  },
  data() {
    return {
      currentImages: this.images || [],
      uploaderIsBusy: false
    }
  },
  methods: {
    /**
     * Get the files that the user wants to upload
     *
     * @param {boolean} loading - there are some files in processing?
     */
    handleProcessingFiles(loading) {
      this.uploaderIsBusy = loading
    },
    /**
     * handle click on "button upload"
     */
    handleAddImage() {
      if (!this.uploaderIsBusy) {
        this.$refs.fileUploader.handleClick()
      }
    },
    /**
     * handle click on "preview image" to show dialog con preview of image
     *
     * @param {number} index - index
     */
    handlePreviewImage(index) {
      this.modifyAppDialog({
        contentComponent: PreviewImage,
        contentComponentProps: { image: this.currentImages[index] },
        hideTitle: true,
        optionsActionsButtons: {
          acceptButtonHide: true,
          cancelButtonText: 'Cerrar'
        },
        visible: true
      })
    },
    /**
     * Handle remove image
     *
     * @param {number} index - index
     */
    async handleRemoveImage(index) {
      if (!this.uploaderIsBusy) {
        try {
          const image = this.currentImages[index]

          if (!image) {
            throw new Error('No existe la imagen que intentó eliminar')
          }

          this.uploaderIsBusy = true
          // Remove image from server
          await this.removeImage(image, index)
        } catch (error) {
          this.handleError(
            error || 'Hubo un error al intentar eliminar la imagen'
          )
        } finally {
          this.uploaderIsBusy = false
        }
      }
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        dismissibleTimeOut: -1,
        type: 'error',
        text: error,
        visible: true
      })
    },
    /**
     * Get the files that the user wants to upload
     *
     * @param {array} files - files array to upload
     */
    async handleUploadFiles(files) {
      const file = files[0]
      // upload image to server
      this.addImage(file)
    },
    /**
     * Handle preview image
     *
     * @param {string} url - image url
     * @param {number} index - index inside "currentImages"
     */
    async removeImage(url, index) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          const ref = await refFromUrl(url)
          // Remove from server
          await deleteFile(ref)
          // Remove from array
          this.currentImages.splice(index, 1)
          // Emit current images
          this.$emit('onCurrentImages', this.currentImages)
        },
        actionButtonText: 'Eliminar',
        dismissibleTimeOut: -1,
        text: '¿Desea eliminar la foto?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Add image to server (Firebase)
     *
     * @param {object} file - file BLOB
     */
    async addImage(file) {
      try {
        this.uploaderIsBusy = true

        const fileName = uniqid()
        const path = this.pathToSave || uniqid()
        const ref = await uploadFile(file, `${path}/${fileName}`)
        const url = await getDownloadURLFromRef(ref)

        // Add to current available images
        this.currentImages.push(url)
        // Emit current images
        this.$emit('onCurrentImages', this.currentImages)
      } catch (error) {
        this.handleError(
          error || 'Hubo un error al intentar eliminar la imagen'
        )
      } finally {
        this.uploaderIsBusy = false
      }
    },
    /**
     * To reset images in component
     */
    onResetImages() {
      this.currentImages = []
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.camera-uploader {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5em;
  .camera-uploader__button-upload {
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed $theme-border-color;
    border-radius: 10px;
  }
  .camera-uploader__list-items {
    .items {
      display: grid;
      grid-gap: 0.5em;
      grid-template-columns: repeat(auto-fit, 100px);
      .item {
        .image {
          position: relative;
          cursor: pointer;
          display: block;
          width: 100px;
          height: 100px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-size: cover;
          background-position: center;
          border-radius: 10px;
          .delete-image {
            position: absolute;
            top: 0;
            right: 10px;
          }
        }
      }
    }
  }
  .camera-uploader__file-uploader {
    display: none;
  }
}

@media (max-width: 600px) {
  .camera-uploader {
    grid-template-columns: initial;
    grid-template-rows: auto 1fr;
    // justify-content: center;
  }
}
</style>
